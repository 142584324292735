import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import en from "../locales/en.json";
import fr from "../locales/fr.json";

export const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français'}
}

i18next
    .use(initReactI18next)
    .use(i18nextBrowserLanguagedetector)
    // .use(Backend)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    about: "about",
                    greetings: "Greetings, I'm Ian Mostar, a dedicated Frontend Developer based in Cebu, Philippines, boasting over 5 years of expertise in web development. My focus lies in crafting dynamic and captivating frontend experiences.",
                    about_text_2: "I specialize in crafting engaging frontend development and have contributed to diverse web applications, including <a href='https://www.rallyhr.com/' rel='noopener noreferrer' target='_blank' title='RallyHR'>HR management</a>, <a href='https://www.aversafe.com/' rel='noopener noreferrer' target='_blank' title='Aversafe'>credential verification</a>, and <a href='https://prophetbettingexchange.com/' rel='noopener noreferrer' target='_blank' title='Prophet Betting Exchange'>betting platforms</a>. I bring valuable experience from being part of teams that launched successful MVP startups.",
                    about_text_3: "Currently, I am deeply entrenched in significant projects with a focus on business listings at my current role in a prominent company. Serving as a key member of an Agile product team, my responsibilities include actively contributing to ensure the seamless delivery of notable product features and updates, with a primary emphasis on frontend development.",
                    about_text_4: "For a detailed overview of my professional journey, feel free to explore my <a href='https://www.linkedin.com/in/ianmostar/' rel='noopener noreferrer' target='_blank' title='LinkedIn'>work experience here</a>.",
                    about_text_5: "Additionally, you can connect with me on <a href='https://twitter.com/iiianous' rel='noopener noreferrer' target='_blank' title='X'>X</a>, where I eagerly share insights and stay abreast of the latest trends in design and web development.",
                    work_title: "work",
                    work_1_title: "Psalm23 Basketball Academy",
                    work_1_desc: "Brochure type website for Basketball enthusiast.",
                    work_2_title: "Balance Spa and Wellness",
                    work_2_desc: "Hypothetical website for a wellness spa."
                }
            },
            fr: {
                translation: {
                    about: 'au sujet de',
                    greetings: "Salutations, je suis Ian Mostar, un développeur Frontend dévoué basé à Cebu, aux Philippines, fort de plus de 5 ans d'expertise en développement web. Mon attention se porte sur la création d'expériences frontend dynamiques et captivantes",
                    about_text_2: "Je me spécialise dans la création de développements frontend captivants et j'ai contribué à diverses applications web, dont la <a href='https://www.rallyhr.com/' rel='noopener noreferrer' target='_blank' title='RallyHR'>gestion des ressources humaines</a> avec RallyHR, la <a href='https://www.aversafe.com/' rel='noopener noreferrer' target='_blank' title='Aversafe'>vérification des qualifications</a> avec Aversafe, et les <a href='https://prophetbettingexchange.com/' rel='noopener noreferrer' target='_blank' title='Prophet Betting Exchange'>plateformes de paris</a> avec Prophet Betting Exchange. Je apporte une expérience précieuse en faisant partie d'équipes ayant lancé avec succès des start-ups MVP.",
                    about_text_3: "Actuellement, je suis fortement impliqué dans des projets importants axés sur les listes d'entreprises dans le cadre de mon rôle actuel au sein d'une entreprise de premier plan. En tant que membre clé d'une équipe produit Agile, mes responsabilités incluent une contribution active pour garantir la livraison fluide de fonctionnalités notables et de mises à jour, mettant l'accent principalement sur le développement frontend.",
                    about_text_4: "Pour obtenir un aperçu détaillé de mon parcours professionnel, n'hésitez pas à explorer mon <a href='https://www.linkedin.com/in/ianmostar/' rel='noopener noreferrer' target='_blank' title='LinkedIn'>expérience professionnelle ici</a>.",
                    about_text_5: "De plus, vous pouvez me rejoindre sur <a href='https://twitter.com/iiianous' rel='noopener noreferrer' target='_blank' title='X'>X</a>, où je partage avec enthousiasme des idées et reste informé des dernières tendances en matière de design et de développement web.",
                    work_title: "travaux",
                    work_1_title: "Académie de Basketball Psaume 23",
                    work_1_desc: "Site Web de type brochure pour les passionnés de basketball.",
                    work_2_title: "Équilibre Spa et Bien-Être",
                    work_2_desc: "Site web hypothétique pour un spa de bien-être."

                }
            }
        },
        // resources: {
        //     en: {
        //         translation: en
        //     },
        //     fr: {
        //         translation: fr
        //     }
        // },
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
    });